import axios from 'axios';

import { BASE_API_URL, USER_DATA } from '../../consts';
import { addGlobalErrors } from '../slices/errors';
import store from '../store';
import {successMessage} from "./index";
import {studentsPageValues} from "../../consts/pages/students";

const axiosExt = config => {
  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Accept-Language': 'en',
    'Content-Type': 'application/json;charset=UTF-8',
    ...config.headers,
  };

  const { Token } = JSON.parse(localStorage.getItem(USER_DATA)) || {};
  headers.Authorization = Token || '';

  return axios({ ...config, url: `${BASE_API_URL}${config.url}`, headers })
    .then(res => {
      const { data: { Status, Msg } = {} } = res;
      // ToDo temp back-end fix
      if (Status !== 'Error' || config.url.includes(studentsPageValues.getItemAPIData.url)) {
        if (config.displaySuccessMessage) {
          successMessage('Done!')
        }
        return Promise.resolve(res);
      }
      return Promise.reject({ message: Msg || 'Unknown error' });
    })
    .catch(error => {
      if (!config.hideGlobalErrors) {
        store.dispatch(
          addGlobalErrors([`${error?.message}`]),
        );
      }
      return Promise.reject(error);
    });
};

export default axiosExt;
export { axiosExt }
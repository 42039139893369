import moment from 'moment/moment';

import { DATE_FORMAT_SLASHED } from '../consts';

import { getUniqueArray, parseFirstName, parseLastName } from './index';

export const parseStudentDataShort = (studentRaw = {}) => {
  return {
    studentDetails: {
      id: studentRaw.ID || '',
      name: parseFirstName(studentRaw.Name),
      lastName: parseLastName(studentRaw.Name),
      yearGroup: studentRaw.YearGroup || '',
      dob: studentRaw.DOB || '',
      nationality: studentRaw.Nationality || '',
      address: studentRaw.Address || '',
      email: studentRaw.StudentEmail || '',
      contact: studentRaw.StudentContactNumber || '',
      schoolLast: studentRaw.SchoolLast || '',
      gender: studentRaw.Gender || '',
      placeBirth: studentRaw.PlaceBirth || '',
      academicYear: studentRaw.AcademicYear || '',
      termEntry: studentRaw.TermEntry || '',
      languages: studentRaw.Languages || '',
      languagesFluent: studentRaw.LanguagesFluent || '',
      status: studentRaw.Status || '',
    },
    otherDetails: studentRaw.Description || '',
    emergencyContact: {
      emergencyName: studentRaw.EmergencyContact || '',
      emergencyAddress: studentRaw.EmergencyContactInfo || '',
    },
    additionalInfo: {
      extraCurricular: studentRaw.ExtraCurricular || '',
      familyCircumstances: studentRaw.FamilyCircumstances || '',
      socialEmotional: studentRaw.SocialEmotional || '',
      learningDifficulties: studentRaw.LearningDifficulties || '',
      arts: studentRaw.Arts || '',
      sports: studentRaw.Sports || '',
      hobbies: studentRaw.Hobbies || '',
    },
    documents: {},
  };
};

export const parseStudentDataFull = student => {
  const parentDetails = {
    name: parseFirstName(student.ParentName1),
    lastName: parseLastName(student.ParentName1),
    relationship: student.RelationShip1 || '',
    parentalStatus: student.Responsible1 || '',
    address: student.ParentAddress1 || '',
    country: student.ParentCountry1 || '',
    emailAddress: student.ParentEmail1 || '',
    contactNumber: student.ParentMobile1 || '',
    profession: student.ParentProfession1 || '',
    city: student.ParentTown1 || '',
    postcode: student.ParentPostcode1 || '',
    source: student.Source || '',
    name2: parseFirstName(student.ParentName2),
    lastName2: parseLastName(student.ParentName2),
    relationship2: student.RelationShip2 || '',
    parentalStatus2: student.Responsible2 || '',
    address2: student.ParentAddress2 || '',
    country2: student.ParentCountry2 || '',
    emailAddress2: student.ParentEmail2 || '',
    contactNumber2: student.ParentMobile2 || '',
    profession2: student.ParentProfession2 || '',
    city2: student.ParentTown2 || '',
    postcode2: student.ParentPostcode2 || '',
  };

  const studentDetails = {
    Pack: student.Pack,
    studentName: parseFirstName(student.Name),
    studentLastName: parseLastName(student.Name),
    dateOfBirth: student.DOB || '', // need to convert to calendar
    placeBirth: student.PlaceBirth || '',
    studentCountry: student.CountryResidence || '',
    nationality: student.Nationality || '',
    studentEmailAddress: student.StudentEmail || '',
    studentContactNumber: student.StudentContactNumber || '',
    academicYear: student.AcademicYear || '',
    yearGroup: student.YearGroup || '', // need to create dropdown list, api yeargroup list
    termEntry: '',
    studentAddress: student.Address || '',
    languages: student.Languages || '',
    fluentLanguages: student.Fluent || '',
    studentOtherDetails: student.Description || '',
    documentsReceived: student.DocumentsReceived || '', // need API list form documents from backend , tick box frontend
    studentStatus: student.Status || '',
    emergencyContactName: student.EmergencyContact || '',
    emergencyContactInfo: student.EmergencyContactInfo || '',
    familyCircumstances: student.FamilyCircumstances || '',
    socialEmotional: student.SocialEmotional || '',
    learningDifficulties: student.LearningDifficulties || '',
    extraCurricular: student.ExtraCurricular || '',
    arts: student.Arts || '',
    sports: student.Sports || '',
    hobbies: student.Hobbies || '',
    gender: student.Gender || '',
    startDate: student.StartDate || '', // convert to calendar
    endDate: student.EndDate || '', // convert to calendar
    DepositReturned: student.DepositReturned || '',
    Fee: student.Fee || '',
    Deposit: student.Deposit || '',
    Cost: student.Cost || '',
    UKAddress: student.UKAddress || '',
    EHEName: student.EHEName || '',
    EHE: student.EHE || '',
    EHCP: student.EHCP || '',
    SENSupport: student.SENSupport || '',
    AudioConsentOnlineChannels: student.AudioConsentOnlineChannels,
    AudioConsentPublicPrivateMedia: student.AudioConsentPublicPrivateMedia,
    AudioConsentPromotion: student.AudioConsentPromotion,
  };

  const otherSchoolsDetails = {
    previousSchoolName: student.SchoolLast || '',
    previousSchoolCountry: student.SchoolLastCountry || '',
    previousSchoolAddress: student.SchoolLastAddress || '',
    previousSchoolEmail: student.SchoolLastEmail || '',
    previousSchoolContactNumber: student.SchoolLastContactNumber || '',
    previousSchoolReportReceived: student.SchoolLastReportReceived || '', // need to convert to tick
    futureSchoolName: student.SchoolNext || '',
    futureSchoolCountry: student.SchoolNextCountry || '',
    futureSchoolAddress: student.SchoolNextAddress || '',
    futureSchoolEmail: student.SchoolNextEmail || '',
    futureSchoolContactNumber: student.SchoolNextContactNumber || '',
    futureSchoolReportReceived: student.SchoolNextReportReceived || '',
    SchoolNextContactDate: student.SchoolNextContactDate || '',
    SchoolNextContactPerson: student.SchoolNextContactPerson || '',
    SchoolLastSHSDSLReceived: student.SchoolLastSHSDSLReceived || '',
    SchoolLastSafeguardContactDate: student.SchoolLastSafeguardContactDate || '',
    SchoolLastSafeguardContactName: student.SchoolLastSafeguardContactName || '',
    SchoolLastSafeguardInfo: student.SchoolLastSafeguardInfo || '',
    // need to convert to tick
  };
  return {
    parentDetails,
    studentDetails,
    otherSchoolsDetails,
  };
};

export const parseRawAPIDateToUserFriendlyString = dateObj =>
  moment(dateObj).isValid() ? moment(dateObj).format(DATE_FORMAT_SLASHED) : 'Unset';

export const parseUserFriendlyStringToRawAPIDate = dateString =>
  moment(dateString, DATE_FORMAT_SLASHED).isValid() ? moment(dateString, DATE_FORMAT_SLASHED) : '';

export const rawToParsedStudentData = (data = {}) => {
  const newData = {
    // Help back-end tricks
    ...data,
    parsedFirstName: parseFirstName(data.Name),
    parsedLastName: parseLastName(data.Name),
    parsedFirstParentFirstName: parseFirstName(data.ParentName1),
    parsedFirstParentLastName: parseLastName(data.ParentName1),
    parsedSecondParentFirstName: parseFirstName(data.ParentName2),
    parsedSecondParentLastName: parseLastName(data.ParentName2),
  };
  if ('DocumentsReceived' in newData) {
    newData.DocumentsReceived = getUniqueArray(data.DocumentsReceived ? data.DocumentsReceived.split(',') : []).join(
      ',',
    );
  }
  return newData;
};

export const uniteFirstAndLastNames = ({ first, last }) => `${first ? `${first} ` : ''}${last || ''}`;

export const parsedToRawStudentData = (data = {}) => {
  const {
    parsedFirstName,
    parsedLastName,
    parsedSecondParentFirstName,
    parsedSecondParentLastName,
    parsedFirstParentFirstName,
    parsedFirstParentLastName,
  } = data;
  // Help back-end tricks
  const resultData = { ...data };
  resultData.Name = uniteFirstAndLastNames({ first: parsedFirstName, last: parsedLastName });
  resultData.ParentName1 = uniteFirstAndLastNames({
    first: parsedFirstParentFirstName,
    last: parsedFirstParentLastName,
  });
  resultData.ParentName2 = uniteFirstAndLastNames({
    first: parsedSecondParentFirstName,
    last: parsedSecondParentLastName,
  });

  if ('DocumentsReceived' in resultData) {
    resultData.DocumentsReceived = getUniqueArray(
      resultData.DocumentsReceived ? resultData.DocumentsReceived.split(',') : [],
    )
      .join(',')
      .replace(/^,|,$/g, '');
  }
  delete resultData.parsedFirstName;
  delete resultData.parsedLastName;
  delete resultData.parsedSecondParentLastName;
  delete resultData.parsedSecondParentFirstName;
  delete resultData.parsedFirstParentLastName;
  delete resultData.parsedFirstParentFirstName;
  return resultData;
};
